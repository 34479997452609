import React, { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const LogoPlansSection = ({ data, plan, service }) => {
  
  const {t} = useTranslation();

  // Mockups
  const [mockupsSelected] = useState({ label: plan.custom.mockups.included, value: plan.custom.mockups.included });

  // Feedbacks
  const [feedbacksSelected] = useState({ label: plan.custom.feedbacks.included, value: plan.custom.feedbacks.included });

  // Graphic Charter
  const [graphicCharterSelected] = useState({ label: t('simulator.sentence.graphicCharterOff'), value: 0 });

  // Simulator
  const [delivered, setDelivered] = useState(null);

  // Update Simulator
  const updateSimulator = value => {

    // Delivered
    if(plan.custom.delivered.active === true) {
      var delivered = plan.custom.delivered.create;

      // Mockups
      if(mockupsSelected.value > 0){
        delivered += plan.custom.delivered.mockup * mockupsSelected.value;
      }

      // Feedbacks
      if(feedbacksSelected.value > 0){
        delivered += plan.custom.delivered.feedback * feedbacksSelected.value;
      }

      // Graphic Charter
      if(graphicCharterSelected.value === 1){
        delivered += plan.custom.delivered.graphic_charter * graphicCharterSelected.value;
      }

      setDelivered(Math.ceil(delivered));
    }

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    updateSimulator();
  });

  return (
    <>
      {service === 'logo' ?    
        <div className="table-responsive w-lg-75 mx-lg-auto" id="plans">
          <table className="table table-lg table-striped table-borderless table-nowrap table-vertical-border-striped">
            <thead className="table-text-center align-middle">
            <tr>
              <th scope="col" style={{width:'40%'}}></th>
              <th scope="col" style={{width:'60%'}}>
                <span className="d-block" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.allTaxesExcluded')} dangerouslySetInnerHTML={{__html: t('plans.logo.from', { service_price: plan.price })}}></span>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.logo.mockups')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.logo.mockupsPopoverTitle')} data-bs-content={t('plans.logo.mockupsPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  {plan.custom.mockups.active === true && 
                    <div><span className="badge bg-soft-primary text-primary rounded-pill" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.mockupTooltip')}>{plan.custom.mockups.included}</span></div>
                  }
                  {plan.custom.mockups.active === true && plan.custom.mockups.number_maximum > 1 && 
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.allTaxesExcluded')}>{t('plans.logo.mockupAdditionalPrice', { service_mockup_additional_price: plan.custom.mockups.additional_price })}</span></div>
                  }      
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.logo.feedbacks')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.logo.feedbacksPopoverTitle')} data-bs-content={t('plans.logo.feedbacksPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  {plan.custom.feedbacks.active === true && 
                    <div><span className="badge bg-soft-primary text-primary rounded-pill" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.feedbackTooltip')}>{plan.custom.feedbacks.included}</span></div>
                  }
                  {plan.custom.feedbacks.active === true && plan.custom.feedbacks.number_maximum > 1 && 
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.allTaxesExcluded')}>{t('plans.logo.feedbackAdditionalPrice', { service_feedback_additional_price: plan.custom.feedbacks.additional_price })}</span></div>
                  }      
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.logo.graphicCharter')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.logo.graphicCharterPopoverTitle')} data-bs-content={t('plans.logo.graphicCharterPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  <div className="pb-2">
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.allTaxesExcluded')}>{t('plans.logo.graphicCharterActivePrice', { service_graphic_charter_active_price: plan.custom.graphic_charter.active_price })}</span></div>
                  </div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.logo.logos')}</span></div>
                  <div><span className="text-muted small">{t('plans.logo.logosDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.logo.typographies')}</span></div>
                  <div><span className="text-muted small">{t('plans.logo.typographiesDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.logo.colorPallet')}</span></div>
                  <div><span className="text-muted small">{t('plans.logo.colorPalletDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.logo.formats')}</span></div>
                  <div><span className="text-muted small">{t('plans.logo.formatsDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">{t('plans.logo.deliveredWithin')} <span className="bi bi-info-circle pointer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.logo.deliveredWithinTooltip')}></span></th>
                <td className="table-text-center align-middle">
                  {delivered && plan.custom.delivered.active === true &&
                    <span>{t('plans.logo.deliveredWithinWorkingDays', { count: delivered, working_days_delivered: delivered })}</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      : null}
    </>
  );
};

export default LogoPlansSection;
