import * as React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Typed from 'react-typed';

// Components
import Layout from '../components/layout/Layout';
import PlansSection from '../components/section/Plans';
import WebsitesPlansSection from '../components/section/plans/Websites';
import SocialMediaOptimizationPlansSection from '../components/section/plans/SocialMediaOptimization';
import CommunityManagementPlansSection from '../components/section/plans/CommunityManagement';
import EmailSignaturePlansSection from '../components/section/plans/EmailSignature';
import LogoPlansSection from '../components/section/plans/Logo';
import BusinessCardPlansSection from '../components/section/plans/BusinessCard';

// Images
import headerBackgroundImage from '../images/svg/components/card-11.svg';

const PricingPage = ({ data }) => {

  const { t } = useTranslation();

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div>
        <div className="position-relative bg-img-start" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} data-aos="fade-up">
          <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-auto mb-9">
              <h1 className="display-6 mb-3">{t('title')}
                <br></br>
                <span className="text-primary text-highlight-behind-warning">
                  <Typed
                    strings={t('titleTyped').split(',')}
                    typeSpeed={40}
                    backSpeed={50}
                    loop >
                  </Typed>
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.resume.title')}</h2>
            <p>{t('pricing.resume.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.resume.frontmatter} service="resume" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.onePage.title')}</h2>
            <p>{t('pricing.onePage.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.one_page.frontmatter} service="one_page" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.showcase.title')}</h2>
            <p>{t('pricing.showcase.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.showcase.frontmatter} service="showcase" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.ecommerce.title')}</h2>
            <p>{t('pricing.ecommerce.subtitle')}</p>
          </div>
          <WebsitesPlansSection data={data} plan={data.ecommerce.frontmatter} service="ecommerce" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.socialMediaOptimization.title')}</h2>
            <p>{t('pricing.socialMediaOptimization.subtitle')}</p>
          </div>
          <SocialMediaOptimizationPlansSection data={data} plan={data.social_media_optimization.frontmatter} service="social_media_optimization" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.communityManagement.title')}</h2>
            <p>{t('pricing.communityManagement.subtitle')}</p>
          </div>
          <CommunityManagementPlansSection data={data} plan={data.community_management.frontmatter} service="community_management" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.emailSignature.title')}</h2>
            <p>{t('pricing.emailSignature.subtitle')}</p>
          </div>
          <EmailSignaturePlansSection data={data} plan={data.email_signature.frontmatter} service="email_signature" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.logo.title')}</h2>
            <p>{t('pricing.logo.subtitle')}</p>
          </div>
          <LogoPlansSection data={data} plan={data.logo.frontmatter} service="logo" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('pricing.businessCard.title')}</h2>
            <p>{t('pricing.businessCard.subtitle')}</p>
          </div>
          <BusinessCardPlansSection data={data} plan={data.business_card.frontmatter} service="business_card" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('pricing.consulting.title')}</h2>
              <p>{t('pricing.consulting.subtitle')}</p>
            </div>
            <PlansSection data={data} plan={data.consulting.frontmatter} service="consulting" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "pricing", "plans", "tools", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    resume: mdx(slug: {eq: "resume"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
    one_page: mdx(slug: {eq: "one_page"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
    showcase: mdx(slug: {eq: "showcase"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
    ecommerce: mdx(slug: {eq: "ecommerce"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        server {
            month_price
            year_price
        }
        custom {
          pages {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          sheets {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          plugins {
              active
              included
              number_minimum
              number_maximum
              additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              page_price
              sheet_price
              plugin_price
              translation_price
          }
          delivered {
              active
              install
              page
              sheet
              plugin
              language {
                install
                page
                sheet
                plugin
              }
              launch
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
    social_media_optimization: mdx(slug: {eq: "social_media_optimization"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          social_medias {
            active
            google {
                active
                photo_active
                service_active
                product_active
            }
            facebook {
                active
                photo_active
                service_active
                product_active
            }
            instagram {
                active
                photo_active
                service_active
                product_active
            }
            linkedin {
                active
                photo_active
                service_active
                product_active
            }
            tiktok {
                active
                photo_active
                service_active
                product_active
            }
            pinterest {
                active
                photo_active
                service_active
                product_active
            }
            pagesjaunes {
                active
                photo_active
                service_active
                product_active
            }
            tripadvisor {
                active
                photo_active
                service_active
                product_active
            }
            thefork {
                active
                photo_active
                service_active
                product_active
            }
            included
            number_minimum
            number_maximum
            active_price
            additional_price
          }
          photos {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          services {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          products {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              photo_price
              service_price
              product_price
          }
          shootings {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          delivered {
              active
              social_media
              photo
              service
              product
              shooting
              language {
                  social_media
                  photo
                  service
                  product
              }
          }
          modifications {
              active
              every_price
              number_minimum
              number_maximum
          }
        }
      }
      id
    }
    community_management: mdx(slug: {eq: "community_management"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          social_medias {
            active
            google {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            facebook {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            instagram {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            linkedin {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            tiktok {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            pinterest {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            pagesjaunes {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            tripadvisor {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            thefork {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            included
            number_minimum
            number_maximum
            active_price
            additional_price
          }
          posts {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          stories {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          reels {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          contests {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          comments {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
              post_price
              story_price
              reel_price
              contest_price
          }
          reviews {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
          }
          messages {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              post_price
              story_price
              reel_price
              contest_price
              comment_price
              review_price
              message_price
          }
          shootings {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
        }
      }
      id
    }
    email_signature: mdx(slug: {eq: "email_signature"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          persons {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          mockups {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          feedbacks {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          icons {
              active
              included
              active_price
          }
          profile_pictures {
              active
              included
              active_price
              additional_price
          }
          company_logo {
              active
              included
              active_price
          }
          setups {
              active
              included
              active_price
              additional_price
          }
          delivered {
              active
              create
              person
              mockup
              feedback
              icon
              profile_picture
              company_logo
              setup
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }
    logo: mdx(slug: {eq: "logo"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          mockups {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          feedbacks {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          graphic_charter {
              active
              included
              active_price
          }
          delivered {
              active
              create
              mockup
              feedback
              graphic_charter
          }
        }
      }
      id
    }
    business_card: mdx(slug: {eq: "business_card"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          persons {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          mockups {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          feedbacks {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          graphic_charter {
              active
              included
              active_price
          }
          delivered {
              active
              create
              person
              mockup
              feedback
              graphic_charter
          }
          modifications {
              active
              every_price,
              number_minimum,
              number_maximum,
          }
        }
      }
      id
    }  
    consulting: mdx(slug: {eq: "consulting"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        day_price
        month_price
      }
      id
    }
  }
`;
